import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import mail from "../Login/mail.svg";
import password from "../Login/password.svg";
import Logo from "../Login/transparentLogo.png"

import createAccountCall from '../../requests/account/create-account';
import { setErrorMessage, setSuccessMessage, renderLogin } from '../account-utils';

const Signup = () => {
   const history = useHistory();
   const [search, setSearch] = useState("");

   async function createAccount() {
      let username = document.getElementsByName('username')[0].value;
      let email = document.getElementsByName('email')[0].value;
      let password = document.getElementsByName('password')[0].value;
      let confirmPassword = document.getElementsByName('confirm-password')[0].value;
      if (!email || !username || !password || !confirmPassword) return setErrorMessage('Missing fields.');
      //check if password and confirm password match
      if (password !== confirmPassword) return setErrorMessage('Passwords do not match.');
      //verify email and password format
      let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email.match(emailRegex)) return setErrorMessage('Email format invalid.');
      let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (!password.match(passwordRegex)) return setErrorMessage('Password must include an uppercase, lowercase, number and special character.');
      let result = await createAccountCall({ email, password, username });
      if (!result) return setErrorMessage('Sign Up Error.');

      //TODO:
      switch (result.status) {
         case 200:
            //if success redirect to login
            console.log(`Created account for ${email}`);
            history.push('/login');
            break;
         case 409:
         case 422:
         default:
            //if fail display error message
            console.log(`Create account failed ${result.status} ${result.message}`);
            setErrorMessage(result.message || 'Sign Up Error.');
            break;
      }
   }

   return (
      <>
         <div className="login">
            <div className="login__col">
               <div className="login__wrap">
                  <div className="login__preview"><img src="img/content/login-pic.png" alt="Login" /></div>
                  <div className="h4 login__subtitle">Plan includes</div>
                  <ul className="login__list">
                     <li>Unlimited product uploads</li>
                     <li>Pro tips</li>
                     <li>Free forever</li>
                     <li>Full author options</li>
                  </ul>
               </div>
            </div>
            <div className="login__col">
               <div className="login__wrapper">
                  <div className="h2 login__title">Sign up</div>
                  <div className="login__entry">
                     <div className="login__fieldset">
                        <div className="field field_icon">
                           <div className="field__wrap">
                              <input className="field__input" type="text" name="username" placeholder="Username" />
                              <div className="field__icon">
                                 <img className="icon icon-mail" src={mail}></img>
                              </div>
                           </div>
                        </div>
                        <div className="field field_icon">
                           <div className="field__wrap">
                              <input className="field__input" type="email" name="email" placeholder="Email" />
                              <div className="field__icon">
                                 <img className="icon icon-mail" src={mail}></img>
                              </div>
                           </div>
                        </div>
                        <div className="field field_icon">
                           <div className="field__wrap">
                              <input className="field__input" type="password" name="password" placeholder="Password" />
                              <div className="field__icon">
                                 <img className="icon icon-mail" src={password}></img>
                              </div>
                           </div>
                        </div>
                        <div className="field field_icon">
                           <div className="field__wrap">
                              <input className="field__input" type="password" name="confirm-password" placeholder="Confirm Password" />
                              <div className="field__icon">
                                 <img className="icon icon-mail" src={password}></img>
                              </div>
                           </div>
                        </div>
                        <button className="button login__button" onClick={createAccount}>Continue</button>
                     </div>
                     <span className="entry__info" name="error-msg" style={{ color: '#FF6A55' }}></span>
                     <div className="entry__info">
                        Have an account? <a onClick={() => renderLogin(history)}>Sign in</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Signup;
