import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

const newsletterSignup = async payload => {
  const url = `${process.env.REACT_APP_API_BASE}api/utils/newsletter`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      data: payload
    });

    return {data, status};
  } catch(e) {
    console.log(e);
    return;
  };
}

export default newsletterSignup;