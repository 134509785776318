import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import mail from "./mail.svg";
import password from "./password.svg";
import Logo from "./transparentLogo.png"

import accountLoginCall from '../../requests/account/login-account';
import { setErrorMessage, setSuccessMessage, renderReset, renderSignup } from '../account-utils';

const Login = () => {
   const history = useHistory();
   const [search, setSearch] = useState("");
   const dispatch = useDispatch();
   const user_data = useSelector(state => state.user_data);

   async function accountLogin() {
      let email = document.getElementsByName('email')[0].value;
      let password = document.getElementsByName('password')[0].value;
      if (!email || !password) return setErrorMessage('Missing fields.');
      //verify email format
      let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email.match(emailRegex)) return setErrorMessage('Email format invalid.');

      let result = await accountLoginCall({ email, password });
      if (!result) return setErrorMessage('Login Error');

      switch (result.status) {
         case 200:
            //if success redirect to dash
            console.log(`Logged in to ${email} ${result.token}`);
            localStorage.setItem('access_token', result.token);
            dispatch({
               type: 'UPDATE_USER_DATA',
               data: result.userData
            });
            history.push('/dashboard');
            break;
         case 422:
         default:
            console.log(`Login failed ${result.status} ${result.message}`)
            setErrorMessage(result.message || 'Login Error');
            break;
      }
   }

   return (
      <>
         <div className="entry">
            <div className="entry__wrapper">
               {/* <a className="entry__logo" href="#">
                  <img className="some-icon" src={Logo} alt="Core" />
               </a> */}
               <div className="h2 entry__title">Sign in</div>
               <div className="entry__fieldset">
                  <div className="field field_icon">
                     <div className="field__wrap">
                        <input className="field__input" type="email" name="email" placeholder="Your email" />
                        <div className="field__icon">
                           <img className="icon icon-mail" src={mail}></img>
                        </div>
                     </div>
                  </div>
                  <div className="field field_icon">
                     <div className="field__wrap">
                        <input className="field__input" type="password" name="password" placeholder="Password" />
                        <div className="field__icon">
                           <img className="icon icon-mail" src={password}></img>
                        </div>
                     </div>
                  </div>
                  <a className="button entry__button" name="sign-in-btn" onClick={accountLogin}>
                     Sign in
                  </a>
               </div>
               <span className="entry__info" name="error-msg" style={{ color: '#FF6A55' }}></span>
               <div className="entry__info">
                  Forgot Password? <a onClick={() => renderReset(history)}>Reset</a>
               </div>
               <div className="entry__info">
                  Don't have an account? <a onClick={() => renderSignup(history)}>Sign up</a>
               </div>
            </div>
         </div>
      </>
   );
};

export default Login;
