import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

import { convertTimestampToDate } from '../../utils/time';

const LogCell = ({ data, children }) => {
   const { pathname } = useLocation();

   useEffect(() => { }, []);
   return (
      <div className="transactions__row">
         <div className="transactions__col">
            <div className="transactions__label">Timestamp</div>{convertTimestampToDate(data.timestamp)}
         </div>
         <div className="transactions__col">
            {data.status == 200 ? (
               <div className="status-green-dark transactions__status">{data.endpointType}</div>
            ) : (
               <div className="status-red-dark transactions__status">{data.endpointType}</div>
            )}
         </div>
         <div className="transactions__col">
            <div className="transactions__product">{data.status}</div>
         </div>
         <div className="transactions__col">
            <div className="transactions__product">{data.title}</div>
         </div>
         <div className="transactions__col">
            <div className="transactions__label">IP</div>{data.reqIp}
         </div>
      </div>
   );
};

export default LogCell;
