import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Get request logs for api key.
 * @param {*} apiKey api protected endpoint
 * @param {*} payload.apiKey 
 * @param {*} payload.timeframe (optional) 1min, 10min, 1hour, 1day, 1week, 1month, 3month, all
 * @param {*} payload.query (optional) string that we want to search for matches
 */
const searchRequestLog = async (payload, apiKey) => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/log/search`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      headers: {
        'x-api-key': apiKey,
      },
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export default searchRequestLog;