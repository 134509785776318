import data from './pricing.json'
import styles from './pricing.module.sass'

import { useHistory } from 'react-router-dom'
import { Zoom } from 'react-toastify';

const Pricing = () => {
    const history = useHistory();

    return <div className={styles.wrapper}>
        <div className={styles.primary}>Pricing</div>
        <div className={styles.secondary}>Start for free. Grow with your community.</div>
        <div className={styles.pricingItems}>
            {data.map((x, index) => {
                return <div className={styles.pricingItem}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.description}>{x.description}</div>
                    <div className={styles.flexWrap}>
                        <div className={styles.price}>{x.price}</div>
                    </div>
                    {
                        x.points.map((x2, index) => {
                            return <div className={styles.point}>
                                <img
                                    src={"https://www.svgrepo.com/show/169312/check-mark.svg"} />
                                {x2}</div>
                        })
                    }
                    <button className={styles.cta} onClick={() => {
                        if (x.button.text == "Let's Talk ➔") {
                            window.open('mailto:accounts@nftaccess.app')
                        } else {
                            history.push(x.button.link)
                        }
                    }}>{x.button.text}</button>
                </div>
            })}
    </div>

    </div >
}

export default Pricing