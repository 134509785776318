const initialState = {
  user_data: null,
  current_chain: null,
  current_address: null,
  home_data: null,
  collections: null,
  collection_mode: null,
  collection_cards: null,
  ticket_tiers: [],
  ticket_num: 1,
  eth_price: 0,
  connected_provider: null
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return {
        ...state,
        user_data: action.data,
      }
    case 'UPDATE_REQUEST_LOG':
      return {
        ...state,
        request_log_data: action.data,
      }
    case 'UPDATE_FILTERED_REQUEST_LOG':
      return {
        ...state,
        filtered_request_log_data: action.data,
      }
    case 'UPDATE_CURRENT_CHAIN':
      return {
        ...state,
        current_chain: action.data
      }
    case 'UPDATE_CURRENT_ADDRESS':
      return {
        ...state,
        current_address: action.data
      }
    case 'UPDATE_HOME_DATA':
      return {
        ...state,
        home_data: action.data
      }
    case 'UPDATE_COLLECTIONS':
      return {
        ...state,
        collections: action.data
      }
    case 'UPDATE_COLLECTION_MODE':
      return {
        ...state,
        collection_mode: action.data
      }
    case 'UPDATE_COLLECTION_CARDS':
      return {
        ...state,
        collection_cards: action.data
      }
    case 'ADD_TICKET_TIER':
      let ticket_tiers = state.ticket_tiers;

      ticket_tiers.push(action.data);

      return {
        ...state,
        ticket_tiers
      }
    case 'SET_TICKET_TIERS':
      return {
        ...state,
        ticket_tiers: action.data
      }
    case 'INCREMENT_TICKET_NUM':
      return {
        ...state,
        ticket_num: action.data
      }
    case 'CLEAR_EVENT_CREATION':
      return {
        ...state,
        collection_mode: null,
        collection_cards: null,
        ticket_tiers: [],
        ticket_num: 1
      }
    case 'SET_ETH_PRICE':
      return {
        ...state,
        eth_price: action.data
      }
    case 'PROVIDER_UPDATE':
      return {
        ...state,
        connected_provider: action.data
      }
    default:
      return {
        ...state
      }
  }
};

export default Reducer;