import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Returns a users plan, email info based on apikey
 * @param {*} apiKey apikey to return user data for
 */
const claimFromEmail = async (claimId, walletAddress) => {
  const url = `${process.env.REACT_APP_API_BASE}/api/v1/nfts/claimFromEmail/:${claimId}`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      data: {
        'walletAddress': walletAddress,
      },
      //validateStatus: false,
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export default claimFromEmail;