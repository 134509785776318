import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import "./styles/app.css";
import "./styles/globals.sass";
import Page from "./components/Page";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoadingBar from "react-top-loading-bar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { IntercomProvider, useIntercom } from "react-use-intercom";
import Search from "./screens/Search02";
import Claim from "./screens/Claim";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Profile from "./screens/Profile";
import Landing from "./screens/Landing";
import PasswordInitReset from "./screens/PasswordInitReset";
import PasswordCompleteReset from "./screens/PasswordCompleteReset";
import Docs from "./screens/Docs";

const INTERCOM_APP_ID = "wqdo9ysr";

const App = () => {
  let web3;
  const history = useHistory();
  const dispath = useDispatch();
  const [progress, setProgress] = useState(0);
  const user_data = useSelector(state => state.user_data);
  console.log(`user data on page load ${JSON.stringify(user_data)}`);

  return (
    <>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <LoadingBar
          color="#3772FF"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <ToastContainer hideProgressBar={true} closeButton={false} />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Page>
                  <Landing />
                </Page>
              )}
            />
            <Route
              exact
              path="/dashboard"
              render={() => (
                <Page>
                  <Dashboard />
                </Page>
              )}
            />
            <Route
              exact
              path="/login"
              render={() => (
                <Page>
                  <Login />
                </Page>
              )}
            />
            <Route
              exact
              path="/reset-password-start"
              render={() => (
                <Page>
                  <PasswordInitReset />
                </Page>
              )}
            />
            <Route
              exact
              path="/reset-password-complete"
              render={() => (
                <Page>
                  <PasswordCompleteReset />
                </Page>
              )}
            />
            <Route
              exact
              path="/signup"
              render={() => (
                <Page>
                  <Signup />
                </Page>
              )}
            />
            <Route
              exact
              path="/profile"
              render={() => (
                <Page>
                  <Profile />
                </Page>
              )}
            />
            <Route
              exact
              path="/docs"
              component={() => {
                // /docs will redirect to gitbook site
                window.location.href = 'https://nft-access.gitbook.io/nft-access-public-api/'
                return null
              }}
            />
            <Route
              exact
              path="/claim/:claimId"
              render={() => (
                <Page>
                  <Claim />
                </Page>
              )}
            />
          </Switch>
        </Router>
      </IntercomProvider>
    </>
  );
};

export default App;
