import React, { useEffect, useState } from "react";
// import styles from './Dashboard.module.sass'
import styles from './overrides.module.sass'
import "./Dashboard.css";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import LogCell from "../../components/Log-Cell";
import getApiPlan from '../../requests/account/api-plan';
import searchRequestLog from '../../requests/account/request-log';

import { useInterval } from '../react-use-interval';
import cn from "classnames";

const Dashboard = () => {
   const history = useHistory();
   const [search, setSearch] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const dispatch = useDispatch();
   const user_data = useSelector(state => state.user_data);
   const request_log_data = useSelector(state => state.request_log_data);
   const filtered_request_log_data = useSelector(state => state.filtered_request_log_data);


   //states for filter dropdown

   //define options as an array, so we can use the first element to set the initial state of the dropdown's selected item
   const options = ["1min","10min", "1hour", "1day", "1week", "1month", "3month", "all"]
   const prettierOptions = ["Last Minute","Last 10 Minutes","Last Hour","Last Day","Last Week","Last Month","Last 3 months","All Time"]
   const [filterOpen,setFilterOpen] = useState(false)
   const [selectedOption,setSelectedOption] = useState(options[5])
   const [displayedOption,setDisplayedOption] = useState(prettierOptions[5])



   const oneWeekMs = 604800000;

   //if no user_data saved return empty component, header component handles auth but just throw this here to avoid errors below
   if (!user_data) {
      return (<></>)
   }

   let { apiKey, apiPlanType, monthlyPlanArray, monthlyRequestLimit, latestPlan, planExpirationDate } = user_data;
   // console.log(`yooo ${apiKey}, ${apiPlanType}, ${monthlyPlanArray}, ${monthlyRequestLimit}`);

   // console.log(`Latest plan ${apiKey}, ${latestPlan}`);

   let monthlyCount = latestPlan.count;

   const getRequestLogData = async () => {
      console.log(`doing the dash 2 thing! ${new Date()}`);
      let payload = {
         apiKey,
         timeframe: '',
         query: ''
      }
      let logResult = await searchRequestLog(payload, apiKey);
      if (logResult.success) {
         //dispatch to update user_data state
         dispatch({
            type: 'UPDATE_REQUEST_LOG',
            data: logResult.logData
         });
      }
   }

   const getApiUserData = async () => {
      console.log(`doing the dash thing! ${new Date()}`);
      let apiResult = await getApiPlan(apiKey);
      if (apiResult.success) {
         //dispatch to update user_data state
         dispatch({
            type: 'UPDATE_USER_DATA',
            data: apiResult.userData
         });
      }
   }

   async function filteredDashRefresh(timeOption){
      console.log(`Sending request w/ filter appended -> ${new Date()}`);
      let payload = {
         apiKey,
         timeframe: timeOption,
         query: ''
      }
      let logResult = await searchRequestLog(payload, apiKey);
      if (logResult.success) {
         //dispatch to update user_data state
         dispatch({
            type: 'UPDATE_REQUEST_LOG',
            data: logResult.logData
         });
      }
   }


   //when user searches update results in real time
   const handleQueryChange = () => {
      let queryVal = document.getElementsByName('query')[0].value;
      //filter by query
      const queryFilter = (query, ...args) => {
         query = query.toLowerCase();
         let isMatch = false;
         for (const arg of args) {
            if (!arg) continue;
            if (arg.toLowerCase().indexOf(query) !== -1) {
               isMatch = true;
               break;
            }
         }
         return isMatch;
      }

      let queryFiltered = request_log_data.filter(({ title, endpointType, status }) => {
         return queryFilter(queryVal, title, endpointType, status);
      });

      console.log(`filtered query`);
      console.log(queryFiltered);
      dispatch({
         type: 'UPDATE_FILTERED_REQUEST_LOG',
         data: queryFiltered
      });
   }

   //this function will fire every time the selected option changes!
   useEffect(()=>{
      filteredDashRefresh(selectedOption).then(() => {
         console.log("Request finished !")
      })
   },[selectedOption])

   //if no request log saved on first load, set it
   if (!request_log_data) {
      getRequestLogData();
   }

   // refresh logs for this user
   async function refreshLogs() {
      filteredDashRefresh(selectedOption).then(() => {
         console.log("Request finished !")
      });
   }

   // if user has queried data, display that data
   let log_data = filtered_request_log_data.length > 0 && filtered_request_log_data.length < request_log_data.length
         ? filtered_request_log_data
         : request_log_data;

   return (
      <>
         <div className="page__inner">
            <div className="page__container">
               <div className="page__title h3">Dashboard</div>
               <div className="card">
                  <div className="income">
                     <div className="income__list">
                        <div className="income__item">
                           <div className="income__icon" style={{ backgroundColor: "#B5E4CA" }}>
                              <svg className="icon icon-activity">
                                 <use xlinkHref="#icon-activity" />
                              </svg>
                           </div>
                           <div className="income__line">
                              <div className="income__details">
                                 <div className="income__label">
                                    Monthly Request Count
                                    <div className="tooltip tooltipstered">
                                       <svg className="icon icon-info">
                                          <use xlinkHref="#icon-info" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className="income__counter">{monthlyCount}</div>
                              </div>
                           </div>
                        </div>
                        <div className="income__item">
                           <div className="income__icon" style={{ backgroundColor: "#CABDFF" }}>
                              <svg className="icon icon-pie-chart">
                                 <use xlinkHref="#icon-pie-chart" />
                              </svg>
                           </div>
                           <div className="income__line">
                              <div className="income__details">
                                 <div className="income__label">
                                    Monthly Request Limit
                                    <div className="tooltip tooltipstered">
                                       <svg className="icon icon-info">
                                          <use xlinkHref="#icon-info" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className="income__counter">{monthlyRequestLimit}</div>
                              </div>
                           </div>
                        </div>
                        <div className="income__item">
                           <div className="income__icon" style={{ backgroundColor: "#B1E5FC" }}>
                              <svg className="icon icon-shopping-bag">
                                 <use xlinkHref="#icon-shopping-bag" />
                              </svg>
                           </div>
                           <div className="income__line">
                              <div className="income__details">
                                 <div className="income__label">
                                    Plan Type
                                    <div className="tooltip tooltipstered">
                                       <svg className="icon icon-info">
                                          <use xlinkHref="#icon-info" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className="income__counter">{apiPlanType.charAt(0).toUpperCase() + apiPlanType.slice(1)}</div>
                                 <div className="income__indicator">
                                    <div className={latestPlan.expireTs > Date.now() + oneWeekMs ? "balance positive" : "balance negative"}>
                                       <svg className="icon icon-arrow-top">
                                          <use xlinkHref="#icon-arrow-top" />
                                       </svg>
                                       Expires {planExpirationDate}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="transactions card">
                  <div className="card__head">
                     <div className="title-blue card__title">Requests</div>

                     <input className={cn("form__input",styles.form_override)} type="text" name="query" placeholder="Search" onChange={() => {handleQueryChange()}} required="required" autoComplete="off" />
                     <select className="select select_small" style={{ display: "none" }}>
                        <option>Last 10 minutes</option>
                        <option>Last hour</option>
                        <option>Last day</option>
                        <option>Last week</option>
                        <option>Last 30 days</option>
                        <option>Last 90 days</option>
                        <option>All time</option>
                     </select>
                     {/* conditionally apply the open class depending on whether or not the dropdown isOpen state is open, and toggle the state on click */}
                     <div className={cn("nice-select select select_small",filterOpen ? "open" : "")} tabIndex={0} onClick={() => {setFilterOpen(!filterOpen)}}>
                        <span className="current">{displayedOption}</span>
                        <ul className={cn("list")}>
                           {
                              options.map((timeOption,index) => {
                                 //remove data value from LI (unneeded)
                                 //add an onclick function to each of the elements in the list that comprises
                                 //the dropdown; when an element is clicked, the React state selectedOption is set to
                                 //whatever value the timeOption is, and the filter expansion state is set to false (close the filter)
                                 return <li  onClick={() => {
                                    setSelectedOption(timeOption)
                                    setFilterOpen(false)
                                    setDisplayedOption(prettierOptions[index])
                                 }} className="option">{prettierOptions[index]}</li>
                              })
                           }
                        </ul>
                     </div>
                     <button className="button-stroke button-small transactions__button" onClick={() => refreshLogs()} >
                           <span>Refresh</span>
                        </button>
                     {/* <button className="button-small card__button">Download SCV</button> */}
                  </div>
                  <div className="transactions__wrapper">
                     <div className="transactions__table">
                        <div className="transactions__row">
                           <div className="transactions__col">Timestamp</div>
                           <div className="transactions__col">Type</div>
                           <div className="transactions__col">Status</div>
                           <div className="transactions__col">Details</div>
                           <div className="transactions__col">IP</div>
                        </div>

                        {/* display log data */}
                        {!log_data || log_data.length === 0 ? '' : log_data.map((x, index) => (
                                 <LogCell data={x}></LogCell>
                        ))}

                     </div>
                     <div className="transactions__foot">
                        <button className="button-stroke button-small transactions__button">
                           {isLoading ? <div className="loader"></div> : <></>}
                           <span>Load more</span>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Dashboard;
