import styles from "./hero.module.sass"
import Signup from "../HomeSignup";

const Hero = () => {
    const items = ["Ethereum", "Solana", "Binance Smart Chain", "Avalanche", "Polygon"]

    return <div className={styles.heroWrapper}>
        <div className={styles.heroLeft}>
            <div className={styles.heroPrimary}>Create Custom IRL Experiences with NFTs</div>
            <div className={styles.heroSecondary}>Through the NFT Access API, we make it easy to create events, issue event tickets, scan event tickets, issue POAPS, mint custom NFTs, and more.
            </div>
            <div className={styles.items}>
                {
                    items.map((x, index) => {
                        return <div className={styles.item}><img src={"/images/" + x + ".png"} /></div>
                    })
                }
            </div>
        </div>
        <div className={styles.heroRight}>
            <Signup />
        </div>
    </div>
}
export default Hero