import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Edit account, used for editing username, potentially editing plan in future
 * @param {*} payload.apiKey require API key as this is private + should prevent unauthorized people editing accounts
 * @param {*} payload.username username to update
  */
const editAccountCall = async payload => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/account/edit`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export default editAccountCall;