import React, { useState } from "react";
import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import styles from './overrides.module.sass'
import editAccountCall from '../../requests/account/edit-account';
import { setErrorMessage, setSuccessMessage } from '../account-utils';
import { convertTimestampToDate } from '../../utils/time';

const Profile = () => {
   const history = useHistory();
   const [search, setSearch] = useState("");
   const dispatch = useDispatch();
   const user_data = useSelector(state => state.user_data);

   //if no user_data saved return empty component, header component handles auth but just throw this here to avoid errors below
   if (!user_data) {
      return (<></>)
   }

   const handleSubmit = (e) => {
      alert();
   };

   //copy api key to keyboard on click + notify
   const copyToClipboard = () => {
      navigator.clipboard.writeText(`${user_data.apiKey}`);
      document.getElementsByName('api-key-msg')[0].textContent = 'Copied API key to clipboard.';
      setTimeout(() => document.getElementsByName('api-key-msg')[0].textContent = '', 3000);
   }

   async function editAccountUsername() {
      let username = document.getElementsByName('username')[0].value;
      if (username === user_data.username) return console.log(`No need to set new username, equal to old.`);
      if (!username) return setErrorMessage('Missing fields.');

      let result = await editAccountCall({ username, apiKey: user_data.apiKey });
      if (!result) return setErrorMessage('Edit Account Error.');

      switch (result.status) {
         case 200:
            console.log(`Edited account username ${JSON.stringify(result.userData)}`);
            //dispatch updated user data
            dispatch({
               type: 'UPDATE_USER_DATA',
               data: result.userData
            });
            //success message
            setSuccessMessage(`Account edited successfully!`);
            break;
         case 422:
         default:
            console.log(`Edit account failed ${result.status} ${result.message}`)
            setErrorMessage(result.message || 'Edit Account Error');
            break;
      }
   }

   return (
      <>
         <div className="page__inner">
            <div className="page__container">
               <div className="page__title h3">Profile</div>
               <div className="create">
                  <div className={cn("create__row",styles.maxW)}>
                     <div className={cn("create__col",styles.maxW)}>
                        <div className="create__card card">
                           <div className="card__head">
                              <div className="title-green card__title">Account Information</div>
                           </div>
                           <div className={cn("field",styles.limitedInput)}>
                              <div className="field__label">
                                 Name
                                 <div className="tooltip tooltipstered">
                                    <svg className="icon icon-info">
                                       <use xlinkHref="#icon-info" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="field__wrap">
                                 <input className="field__input" type="text" name="username" required defaultValue={user_data.username} />
                              </div>
                           </div>
                           <div className={cn("field",styles.limitedInput)}>
                              <div className="field__label" >
                                 Email
                                 <div className="tooltip tooltipstered">
                                    <svg className="icon icon-info">
                                       <use xlinkHref="#icon-info" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="field__wrap">
                                 <input className="field__input" type="text" name="email" style={{ color: 'grey' }} disabled={true} value={user_data.email} />
                              </div>
                           </div>
                           <div className={cn("field",styles.limitedInput)}>
                              <div className="field__label">
                                 API Key
                                 <div className="tooltip tooltipstered">
                                    <svg className="icon icon-info">
                                       <use xlinkHref="#icon-info" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="field__wrap" onClick={copyToClipboard}>
                                 <input className="field__input" type="text" name="api-key" style={{ color: 'grey' }} disabled={true} value={user_data.apiKey} />
                                 <div className="panel__info" name="api-key-msg" style={{ color: '#83BF6E' }}></div>
                              </div>
                           </div>
                           <div className="panel__info" name="success-msg" style={{ color: '#83BF6E' }}></div>
                           <div className="panel__info" name="error-msg" style={{ color: '#FF6A55' }}></div>
                           <div className={cn("panel", styles.panel)}>
                              <div className="panel__info">
                                 Last saved <span>{convertTimestampToDate(user_data.lastUpdatedTs)}</span>
                              </div>
                              <div className="panel__btns">
                                 <button className="button panel__button" onClick={editAccountUsername}>Save</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Profile;
