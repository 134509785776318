/**
 * Idk if we wanna move this elsewhere but just making util file to avoid duplicate code
 */

export function renderReset(history) {
  history.push('/reset-password-start');
}

export function renderSignup(history) {
  history.push('/signup');
}

export function renderLogin(history) {
  history.push('/login');
}

export function setErrorMessage(msg) {
  document.getElementsByName('error-msg')[0].textContent = msg;
  setTimeout(() => document.getElementsByName('error-msg')[0].textContent = '', 3000);
}

export function setSuccessMessage(msg) {
  document.getElementsByName('success-msg')[0].textContent = msg;
  setTimeout(() => document.getElementsByName('success-msg')[0].textContent = '', 3000);
}
