import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Call to send an email to account we want to reset password for
 * @param {*} payload.emailToReset account email we want to send reset email to
 */
const passwordInitResetCall = async payload => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/account/reset-password`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

/**
 * Call to save new password on reset
 * @param {*} token reset password token, verified on api side
 * @param {*} payload.newPassword new password being set
 */
const passwordCompleteResetCall = async (payload, token) => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/account/reset/${token}`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export { passwordInitResetCall, passwordCompleteResetCall };