import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Create account
 * @param {*} payload.username username to tie account to
 * @param {*} payload.email email for account, will return error if account already exists
 * @param {*} payload.password password to set for account, case sensitive
 */
const createAccountCall = async payload => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/account/create`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export default createAccountCall;