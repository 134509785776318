import React, { useState } from "react";
import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
import styles from './login.module.sass'

import mail from "../../../Login/mail.svg";
import password from "../../../Login/password.svg";

import createAccountCall from '../../../../requests/account/create-account';
import { setErrorMessage, setSuccessMessage, renderLogin } from '../../../account-utils';

const Signup = () => {
    const history = useHistory();
    const [search, setSearch] = useState("");

    async function createAccount() {
        let username = document.getElementsByName('username')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let password = document.getElementsByName('password')[0].value;
        let confirmPassword = document.getElementsByName('confirm-password')[0].value;
        if (!email || !username || !password || !confirmPassword) return setErrorMessage('Missing fields.');
        //check if password and confirm password match
        if (password !== confirmPassword) return setErrorMessage('Passwords do not match.');
        //verify email and password format
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email.match(emailRegex)) return setErrorMessage('Email format invalid.');
        let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!password.match(passwordRegex)) return setErrorMessage('Password must include an uppercase, lowercase, number and special character.');
        let result = await createAccountCall({ email, password, username });
        if (!result) return setErrorMessage('Sign Up Error.');

        //TODO:
        switch (result.status) {
            case 200:
                //if success redirect to login
                console.log(`Created account for ${email}`);
                history.push('/login');
                break;
            case 409:
            case 422:
            default:
                //if fail display error message
                console.log(`Create account failed ${result.status} ${result.message}`);
                setErrorMessage(result.message || 'Sign Up Error.');
                break;
        }
    }

    return (
        <>         <div className={cn("login__wrapper", styles.loginWrap)}>
            <div className="login__entry">
                <div className={styles.createAccount}>Start Creating Now</div>
                <div className={cn("login__fieldset")}>
                    <div className={cn("field field_icon", styles.loginField)}>
                        <div className="field__wrap">
                            <input className="field__input" type="email" name="email" placeholder="Email" />
                            <div className="field__icon">
                                <img className="icon icon-mail" src={mail}></img>
                            </div>
                        </div>
                    </div>
                    <div className={cn("field field_icon", styles.loginField)}>
                        <div className="field__wrap">
                            <input className="field__input" type="password" name="password" placeholder="Password" />
                            <div className="field__icon">
                                <img className="icon icon-mail" src={password} />
                            </div>
                        </div>
                    </div>
                    <div className={cn("field field_icon", styles.loginField)}>
                        <div className="field__wrap">
                            <input className="field__input" type="password" name="confirm-password" placeholder="Confirm Password" />
                            <div className="field__icon">
                                <img className="icon icon-mail" src={password}></img>
                            </div>
                        </div>
                    </div>
                    <button className={cn("button login__button", styles.submitBtn)} onClick={createAccount}>Create Free Account</button>
                </div>
                <span className="entry__info" name="error-msg" style={{ color: 'red' }}></span>
                <div className="entry__info">
                    Have an account? <a onClick={() => renderLogin(history)}>Sign in</a>
                </div>
            </div>
        </div>
        </>
    );
};

export default Signup;
