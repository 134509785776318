import React, { useState } from "react";
import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
import HeroRight from './components/HeroTop'
import styles from './landing.module.sass'
import HeroPartition from "./components/HeroPartition";
import HeroLeft from "./components/HeroLeft";
import Pricing from './components/Pricing'
import Features from './components/Highlights'
import Example from "./components/Example";

const Landing = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
      <>
    <div className={styles.landingWrap}>
      <div className={styles.landingContent}>
        <HeroRight/>
        <HeroLeft/>
      </div>

    </div>
      <div className={styles.landingContent}>
        <Features />
        <Pricing />
        <Example />
      </div>


    </>
  );
};

export default Landing;