import React, { useState } from "react";
import cn from "classnames";
import { useHistory, useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import claimFromEmail from '../../requests/nft/claimFromEmail';
import styles from './overrides.module.sass'
import { setErrorMessage, setSuccessMessage } from '../account-utils';

const Claim = () => {
    const history = useHistory();
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const user_data = useSelector(state => state.user_data);

    // get query params
    let { claimId } = useParams();
    if (!claimId) return setErrorMessage('This link is invalid.');

    //if no user_data saved return empty component, header component handles auth but just throw this here to avoid errors below
    if (!user_data) {
        return (<></>)
    }

    const handleSubmit = (e) => {
        alert();
    };

    async function claimNft(claimId) {
        // get variables and check preconditions
        let walletAddress = document.getElementsByName('walletAddress')[0].value;
        if (!walletAddress) return setErrorMessage('Missing fields.');
        claimId = claimId.substring(1);

        // mint nft to wallet
        const result = await claimFromEmail(claimId, walletAddress);
        if (!result) return setErrorMessage('Error claiming NFT.');
        switch (result.status) {
            case 200:
                console.log(`Claimed NFT! Transaction hash: ${result.transaction}`);
                break;
            default:
                console.log(`Error minting with status ${result.status}: ${result.message}`);
                break;
        }
    }

    return (
        <>
            <div className="page__inner">
                <div className="page__container">
                    <div className="page__title h3">Claim</div>
                    <div className="create">
                        <div className="create__row">
                            <div className="create__col">
                                <div className="create__card card">
                                    <div className="card__head">
                                        <div className="title-green card__title">Claim Information</div>
                                    </div>
                                    <div className="field">
                                        <div className="field__label">
                                            Wallet Address
                                            <div className="tooltip tooltipstered">
                                                <svg className="icon icon-info">
                                                    <user xlinkHref="#icon-info" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="field__wrap">
                                            <input className="field__input" type="text" name="walletAddress" required />
                                        </div>
                                    </div>
                                    <div className="panel__info" name="success-msg" style={{ color: '#83BF6E' }}></div>
                                    <div className="panel__info" name="error-msg" style={{ color: '#FF6A55' }}></div>
                                    <div className={cn("panel", styles.panel)}>
                                        <div className="panel__btns">
                                            <button className="button panel__button" onClick={() => claimNft(claimId)}>Claim</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Claim;
