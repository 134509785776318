import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import searchStyles from "./Search.module.sass";
import Icon from "../Icon";

/* modules */
import Web3 from 'web3';

import { useSelector, useDispatch } from 'react-redux'

import lockedRoutes from '../../resources/locked_routes.json';
import { isBigNumber } from "web3-utils";

import { getConnectedProvider } from '../../utils/getProvider';

import ReactImageAppear from 'react-image-appear';

const jwt = require('jsonwebtoken');

const nav = [
  {
    url: "/dashboard",
    title: "Dashboard",
    type: 'b',
    requireLogin: true,
  },
  {
    url: "/docs",
    title: "Docs",
    type: 'b',
    requireLogin: false,
  }
];

const filteredData1 = ['fuck', 'retire', 'please']


const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [btn, setBtn] = useState(<Link className={cn("button-small", styles.button)} to="/login">Login</Link>)
  const location = useLocation();
  const history = useHistory();

  /* search states */
  const [ulHidden, setUlHidden] = useState(false);

  let web3;
  const dispatch = useDispatch();
  const user_data = useSelector(state => state.user_data);
  const access_token = useSelector(state => state.access_token);
  const connected_provider = useSelector(state => state.connected_provider);
  const current_chain = useSelector(state => state.current_chain);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [hiddenElement, setHidden] = useState(Math.random());
  // const [loggedIn, setLoggedIn] = useState(false)
  let loggedIn = user_data && localStorage.getItem('access_token');

  const handleSubmit = (e) => {
    alert();
  };

  useEffect(() => {
    if (location.pathname) {
      setHidden(Math.random());
    } // remove the old page data every time the dynamic url changes
  }, [location.pathname])

  useEffect(() => {
    console.log('-> USE EFFECT AT HEADER TRIGGERED <-');
    let token = localStorage.getItem('access_token');

    console.log('TOKEN -> ', token);

    // Checks if jwt is undefined, and if the user attempted to navigate to a locked route. redirects to the login page
    console.log('PATHNAME -> ', location.pathname);
    if (token == null && lockedRoutes.includes(location.pathname)) history.push('/login');

    const delayDebounceFn = setTimeout(() => {
      if (wordEntered.length > 0) handleFilter();
    }, 300);

    return () => clearTimeout(delayDebounceFn)
  }, [user_data, access_token, wordEntered, hiddenElement]);

  const clearInput = () => {
    setTimeout(() => {
      setFilteredData([]);
      setWordEntered("");
      setTotalLength(0)
    }, 250)
  };

  useEffect(() => {
    console.log("Nav visibility updated", visibleNav)
  }, [visibleNav])

  function logout() {
    localStorage.removeItem('access_token');
    dispatch({
      type: 'UPDATE_USER_DATA',
      data: null
    });
    dispatch({
      type: 'UPDATE_REQUEST_LOG',
      data: null
    });
    history.push('/login');
    // window.location.reload();
    return;
  }


  let buttonOne = loggedIn ? 'Profile' : 'Sign Up';
  let buttonOnePath = loggedIn ? '/profile' : '/sign-up';
  let buttonTwo = loggedIn ? 'Logout' : 'Login';
  let buttonTwoPath = loggedIn ? '/logout' : '/login';

  const handleNav = (button) => {
    switch (button) {
      case 'Profile':
        history.push('/profile');
        break;
      case 'Logout':
        logout();
        break;
      case 'Sign Up':
        history.push('/signup');
        break;
      case 'Login':
        history.push('/login');
        break;
    }
    console.log(`YOO ${button}`);
  }

  console.log("Location -> ", location.pathname)
  console.log("Is home -> ", location.pathname == '/' ? "Yes" : "No")
  return (
    <header className={styles.header}>
      <div className={cn(styles.container, location.pathname == '/' ? styles.home : "", location.pathname == '/docs' ? styles.docs : "")}>
        <Link className={styles.logo} to="/" onClick={e => {
          e.preventDefault();
          history.push('/');
          setVisibleNav(false);
        }}>
          <img
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="NFT Access"
          />
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              x.type == 'a' ?
                <a
                  className={cn(styles.link, styles.hidetablet)}
                  href="https://testflight.apple.com/join/YBnyk8qT"
                  target="_blank"
                >
                  Mobile App
                </a> :

                <Link
                  className={cn(styles.link, x.requireLogin ? (loggedIn ? "" : styles.hide) : "")}
                  // activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(x.url);
                    setVisibleNav(false);
                  }}
                >
                  {x.title}
                </Link>
            ))}
          </nav>
        </div>
        { /* <Notification className={styles.notification} /> */}
        <Link
          className={cn("button-small", styles.button)}
          // to={buttonOnePath}
          onClick={() => handleNav(buttonOne)}>
          {buttonOne}
        </Link>
        <Link
          className={cn("button-small", styles.button)}
          // to={buttonTwoPath}
          onClick={() => handleNav(buttonTwo)}>
          {buttonTwo}
        </Link>
        {/* <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/connect-wallet"
        >
          Connect Wallet
        </Link> */}
        {/* {btn} */}
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
