const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function convertDate(date) {
  date = date.split("-");
  return date[2] + " " + months[Number(date[1]) - 1] + " " + date[0];
};

/**
 * Convert epoch timestamp to 4/12/2022, 6:01:02 PM format
 * @param {*} timestamp 
 */
function convertTimestampToDate(timestamp) {
  let formatted = new Date(timestamp).toLocaleString();
  return formatted;
}

export { convertDate, convertTimestampToDate };