import data from './highlights.json'
import styles from './highlights.module.sass'

const Pricing = () => {
    return <div className={styles.wrapper}>
        <div className={styles.primary}>It's Like You Just Got Wings</div>
        <div className={styles.secondary}>Creating, securing, and rewarding token-centered communities has never been easier. </div>
        <div className={styles.featureItems}>
            {data.map((x,index) => {
                return <div className={styles.featureItem}>
                    <img src={"https://www.svgrepo.com/show/245645/code.svg"}/>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.description}>{x.description}</div>
                </div>
            })}
        </div>

    </div>
}

export default Pricing