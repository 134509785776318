import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import password from "../Login/password.svg";
import Logo from "../Login/transparentLogo.png"

import { passwordCompleteResetCall } from '../../requests/account/password-reset';
import { setErrorMessage, setSuccessMessage, renderSignup } from '../account-utils';

/**
 * Page to request email to reset password
 */
const PasswordCompleteReset = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");

  async function resetPassword() {
    let password = document.getElementsByName('password')[0].value;
    let confirmPassword = document.getElementsByName('confirm-password')[0].value;
    if (!password || !confirmPassword) return setErrorMessage('Missing fields.');
    //check if password and confirm password match
    if (password !== confirmPassword) return setErrorMessage('Passwords do not match.');
    let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!password.match(passwordRegex)) return setErrorMessage('Password must include an uppercase, lowercase, number and special character.');

    const params = new URLSearchParams(window.location.search)
    let token = params.get('token');
    if (!token) return setErrorMessage('Link invalid. Please load link from email.');
    let result = await passwordCompleteResetCall({ newPassword: password }, token);
    if (!result) return setErrorMessage('Password reset failed.');

    switch (result.status) {
      case 200:
        //if success redirect to login
        console.log(`New password set`)
        history.push('/login');
        break;
      case 422:
      default:
        console.log(`Complete password reset failed ${result.status} ${result.message}`)
        setErrorMessage(result.message || 'Password reset failed.');
        break;
    }
  }

  return (
    <>
      <div className="entry">
        <div className="entry__wrapper">
          {/* <a className="entry__logo" href="#">
                  <img className="some-icon" src={Logo} alt="Core" />
               </a> */}
          <div className="h4 entry__title">New Password</div>
          <div className="entry__fieldset">
            <div className="field field_icon">
              <div className="field__wrap">
                <input className="field__input" type="password" name="password" placeholder="Password" />
                <div className="field__icon">
                  <img className="icon icon-mail" src={password}></img>
                </div>
              </div>
            </div>
            <div className="field field_icon">
              <div className="field__wrap">
                <input className="field__input" type="password" name="confirm-password" placeholder="Confirm Password" />
                <div className="field__icon">
                  <img className="icon icon-mail" src={password}></img>
                </div>
              </div>
            </div>
            <a className="button entry__button" name="sign-in-btn" onClick={resetPassword}>Change Password</a>
          </div>
          <span className="entry__info" name="error-msg" style={{ color: '#FF6A55' }}></span>
          <span className="entry__info" name="success-msg" style={{ color: '#83BF6E' }}></span>
          <div className="entry__info">
            Don't have an account? <a onClick={() => renderSignup(history)}>Sign up</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordCompleteReset;
