import styles from './example.module.sass'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

const Pricing = () => {
    return <div className={styles.wrapper}>
        <div className={styles.primary}>Ready to get started?</div>
        <div className={styles.secondary}></div>
        <div className={styles.integration}>
            <AceEditor
                mode="javascript"
                theme="monokai"
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true, $readOnly: true}}
                value={"import requests\n" +
                    "\n" +
                    "url = \"http://localhost:7777/api/v1/plan\"\n" +
                    "\n" +
                    "headers = {\n" +
                    "  'x-api-key': '5JUW72C40DOLWJSF'\n" +
                    "}\n" +
                    "\n" +
                    "response = requests.request(\"GET\", url, headers=headers)\n" +
                    "\n" +
                    "print(response.text)"}
                readOnly={true}
            />
        </div>

    </div>
}

export default Pricing