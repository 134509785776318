import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import mail from "../Login/mail.svg";
import password from "../Login/password.svg";
import Logo from "../Login/transparentLogo.png"

import { passwordInitResetCall } from '../../requests/account/password-reset';
import { setErrorMessage, setSuccessMessage, renderSignup } from '../account-utils';

/**
 * Page to request email to reset password
 */
const PasswordInitReset = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");

  //send email to reset password, begins reset process
  async function sendResetEmail() {
    let email = document.getElementsByName('email')[0].value;
    if (!email) return setErrorMessage('Missing fields.');
    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email.match(emailRegex)) return setErrorMessage('Email format invalid.');

    let result = await passwordInitResetCall({ emailToReset: email });
    if (!result) return setErrorMessage('Password reset request failed.');

    switch (result.status) {
      case 200:
        console.log(`Sent email ${email}`)
        setSuccessMessage('Check email for reset instructions.');
        break;
      case 422:
      default:
        console.log(`Init password reset failed ${result.status} ${result.message}`)
        setErrorMessage(result.message || 'Password reset request failed.');
        break;
    }
  }

  return (
    <>
      <div className="entry">
        <div className="entry__wrapper">
          {/* <a className="entry__logo" href="#">
                  <img className="some-icon" src={Logo} alt="Core" />
               </a> */}
          <div className="h4 entry__title">Reset Password</div>
          <div className="entry__fieldset">
            <div className="field field_icon">
              <div className="field__wrap">
                <input className="field__input" type="email" name="email" placeholder="Your email" />
                <div className="field__icon">
                  <img className="icon icon-mail" src={mail}></img>
                </div>
              </div>
            </div>
            <a className="button entry__button" name="sign-in-btn" onClick={sendResetEmail}>Send Reset Email</a>
          </div>
          <span className="entry__info" name="error-msg" style={{ color: '#FF6A55' }}></span>
          <span className="entry__info" name="success-msg" style={{ color: '#83BF6E' }}></span>
          <div className="entry__info">
            Don't have an account? <a onClick={() => renderSignup(history)}>Sign up</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordInitReset;
