import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

/**
 * Login to account api call
 * @param {*} payload.email account email
 * @param {*} payload.password case sensitive pass for account
 */
const accountLoginCall = async payload => {
  const url = `${process.env.REACT_APP_API_BASE}/api/website/account/login`;

  try {
    let { data, status } = await axios({
      method: 'post',
      url,
      validateStatus: false,
      data: payload
    });

    //api returns status value in body so just return this
    return data;
  } catch (e) {
    console.log(e);
    return;
  };
}

export default accountLoginCall;