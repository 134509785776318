import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Form from "../Form";

import { toast } from 'react-toastify';

import newsletterSignup from "../../requests/etc/newsletterSignup";

const items = [
  {
    title: "Connect",
    menu: [
      {
        title: "Twitter",
        url: "https://twitter.com/nftaccess_",
      },
      {
        title: "Discord",
        url: "https://discord.gg/uDfWwBRpvU",
      },
      {
        title: "Instagram",
        url: "https://instagram.com/nftaccess_",
      },
      {
        title: "Guides",
        url: "https://intercom.help/nft-access/en/",
      },
    ],
  },
  {
    title: "Info",
    menu: [
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "Docs",
        url: "/",
      },
      {
        title: "Brand",
        url: "https://medium.com",
      },
      {
        title: "Terms of Service",
        url: "",
      },
    ],
  },
];

const Footers = () => {
  const [email, setEmail] = useState('');
  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  const location = useLocation();

  console.log('LOCATION -> ', location);
  let isHome = (location.pathname == '/' ? true : false);

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      // validate email
      let test = regex.test(email);

      if(!test) {
        toast.error('Invalid email. 😣');
        return;
      }

      let result = await newsletterSignup({ email });

      if(result.data.success) {
        toast.success("You're all set to stay up to date with everything NFT Access. 🥳");
        setEmail('');
      } else if(!result.data.success && result.data.exists) {
        toast.error("This emails already registered! 🤪");
      } else {
        toast.error('Error signing up for newsletter. 😔');
      }
    } catch(err) {
      console.log('News letter error -> ', err);
      toast.error('Error signing up for newsletter. 😔');
    }
  }

  return (
    <footer className={styles.footer} style={isHome ? { marginTop: '10px'  } : {}}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <img
                className={styles.pic}
                src="/images/logo-dark.png"
                srcDark="/images/logo-light.png"
                alt="NFT Access"
              />
            </Link>
            {/* <div className={styles.info}>Connecting people around the world.</div> */}
           {/*<div className={styles.version}>
              <div className={styles.details}>Dark theme</div>
              <Theme className="theme-big" />
             </div>*/}
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Join Newsletter</div>
            <div className={styles.text}>Stay notified about everything NFT Access 🥳</div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={(e) => handleSubmit(e)}
              placeholder="Enter your email"
              name="email"
              type="text"
            />
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2022 NFT Access Management LLC. All rights reserved
          </div>
          {/*<div className={styles.note}>
            We use cookies for better service. <a href="/#">Accept</a>
          </div>*/}
        </div>
      </div>
    </footer>
  );
};

export default Footers;
