import styles from "./hero.module.sass"

import { useHistory } from "react-router-dom"


const Hero = () => {
    const history = useHistory();
    return <div className={styles.heroWrapper}>

        <div className={styles.heroLeft}>
            <img src={"https://media.discordapp.net/attachments/945716155425902645/962144889888469132/carbon_4.png"} />

        </div>
        <div className={styles.heroRight}>
            <div className={styles.heroPrimary}>Define Your Own Ticketing Experience</div>
            <div className={styles.heroSecondary}>We get it. Interfaces are never one-size fits all. We allow you to define your own ticketing experience from the ground up; create systems to control how your tickets are issued, verified, and distributed.
            </div>
            <div className={styles.heroBtns}>
                <button onClick={() => window.open("https://nft-access.gitbook.io/nft-access-public-api/")} className={styles.heroBtn}>Let's See the Docs</button>
            </div>
        </div>
    </div>
}
export default Hero